import React, { Component } from 'react';
import { Button, Modal, Popover, Card, Alert, Space, Switch, message } from '@nackle/paper';
import { QuestionCircleFilled } from '@ant-design/icons';
import noop from 'lodash/noop';
import { connect } from 'react-redux';
import { AppState, Preferences, Tokens, Games } from '../../dux';
import { ReduxProps, Translate, Translations } from '../..';
import { mapData } from '../../util/Loadable';
import { RobotIcon } from './AutoPlayRobot';
import { AutoPlaySwitchText } from '../../translation-data';

const mapStateToProps = (state: AppState) => ({
    autoplayEnabled: Preferences.getAutoPlayPreference(state),
    autoplayPreferenceEnabled: Games.getAutoPlayPreferenceEnabled(state),
    availableTokenCount: mapData(Tokens.getAvailableTokens(state), (tokens) => tokens.length),
    autoPlayLabel: Translations.getTranslation(state,AutoPlaySwitchText.LABEL)
});

const mapDispatchToProps = {
    setAutoPlayPreference: Preferences.setAutoPlayPreference,
};

interface Props {
    className?: string;
}

interface State {
    confirmEnableIsOpen: boolean;
    confirmDisableIsOpen: boolean;
    isChanging: boolean;
}

class AutoPlayController extends Component<ReduxProps<Props, typeof mapStateToProps, typeof mapDispatchToProps>, State> {
    public state = {
        confirmEnableIsOpen: false,
        confirmDisableIsOpen: false,
        isChanging: false,
    };

    public render() {
        const {
            autoplayEnabled,
            autoplayPreferenceEnabled,
            availableTokenCount,
            className = '',
            autoPlayLabel
        } = this.props;

        const onClickSwitch = autoplayEnabled.isLoaded ? autoplayEnabled.data ? this.handleDisable : this.handleEnable : noop;

        // Hide the auto-play switch if it's disabled.
        if (autoplayPreferenceEnabled.isLoaded && !autoplayPreferenceEnabled.data) {
            return <div className={className} />;
        }

        return (
            <>
                <Switch
                    checked={autoplayEnabled.isLoaded && autoplayEnabled.data}
                    disabled={this.state.isChanging}
                    onChange={onClickSwitch}
                    size="small"
                    aria-label={autoPlayLabel}
                />
                <Space>
                    <br/>
                    <br/>
                </Space>
                &nbsp;
                <Translate id={AutoPlaySwitchText.LABEL} />
                <Popover
                    trigger="click"
                    destroyTooltipOnHide
                    content={(
                        <Card style={{ maxWidth: '30em' }}>
                            <p><b><Translate id={AutoPlaySwitchText.INFO_HEADER} /></b></p>

                            <Translate id={AutoPlaySwitchText.INFO_BODY} parseHtml />

                            {autoplayEnabled.isLoaded && autoplayEnabled.data && <p><Translate id={AutoPlaySwitchText.INFO_ENABLED_LABEL} parseHtml /></p>}
                            {autoplayEnabled.isLoaded && !autoplayEnabled.data && <p><Translate id={AutoPlaySwitchText.INFO_DISABLED_LABEL} parseHtml /></p>}
                        </Card>
                    )}
                    placement="bottom"
                >
                    <Button
                        icon={<QuestionCircleFilled/>}
                        id="auto-play-help-button"
                        style={ { border: 0 } }
                    />
                </Popover>

                <Modal
                    visible={this.state.confirmEnableIsOpen}
                    onCancel={this.handleCloseEnable}
                    title={<Translate id={AutoPlaySwitchText.ENABLE_HEADER} />}
                    centered
                    closable
                    footer={[
                        (
                            <Button
                                disabled={this.state.isChanging}
                                onClick={this.handleCloseEnable}
                            >
                                <Translate id={AutoPlaySwitchText.ENABLE_CANCEL} />
                            </Button>
                        ), (
                            <Button
                                loading={this.state.isChanging}
                                type="primary"
                                onClick={this.handleConfirmEnable}
                            >
                                <Translate id={AutoPlaySwitchText.ENABLE_CONFIRM} />
                            </Button>
                        ),
                    ]}
                >
                    <RobotIcon style={{ float: 'right', marginLeft: '1em' }} />

                    <Translate id={AutoPlaySwitchText.ENABLE_BODY} parseHtml />

                    { availableTokenCount.isLoaded && availableTokenCount.data > 0 && (
                        <Alert
                            message={(
                                <Translate
                                    args={{
                                        playCount: availableTokenCount.data,
                                    }}
                                    id={AutoPlaySwitchText.ENABLE_CONSEQUENCE}
                                    parseHtml
                                />
                            )}
                            type="error"
                        />
                    )}
                </Modal>

                <Modal
                    visible={this.state.confirmDisableIsOpen}
                    onCancel={this.handleCloseDisable}
                    title={<Translate id={AutoPlaySwitchText.DISABLE_HEADER} />}
                    footer={[
                        (
                            <Button
                                disabled={this.state.isChanging}
                                onClick={this.handleCloseDisable}
                            >
                                <Translate id={AutoPlaySwitchText.DISABLE_CANCEL} />
                            </Button>
                        ), (
                            <Button
                                loading={this.state.isChanging}
                                type="primary"
                                onClick={this.handleConfirmDisable}
                            >
                                <Translate id={AutoPlaySwitchText.DISABLE_CONFIRM} />
                            </Button>
                        ),
                    ]}
                >
                        <p><Translate id={AutoPlaySwitchText.DISABLE_BODY} /></p>
                </Modal>
                </>
        );
    }

    private handleEnable = () => this.setState({ confirmEnableIsOpen: true });
    private handleCloseEnable = () => this.setState({ confirmEnableIsOpen: false });
    private handleConfirmEnable = async () => {
        try {
            this.setState({ isChanging: true });
            await this.props.setAutoPlayPreference(true);
        } catch (error: any) {
            console.error(error);
            message.error(error)
            throw new Error(error);
        } finally {
            this.handleCloseEnable();
            this.setState({ isChanging: false });
        }
    }

    private handleDisable = () => this.setState({ confirmDisableIsOpen: true });
    private handleCloseDisable = () => this.setState({ confirmDisableIsOpen: false });
    private handleConfirmDisable = async () => {
        try {
            this.setState({ isChanging: true });
            await this.props.setAutoPlayPreference(false);
        } catch (e) {
            console.error(e);
            // tslint:disable-next-line:no-unused-expression
            message.error;
            throw e;
        } finally {
            this.handleCloseDisable();
            this.setState({ isChanging: false });
        }
    }
}

const bound = connect(mapStateToProps, mapDispatchToProps)(AutoPlayController);
export { bound as AutoPlayController };
